import Alpine from 'alpinejs'

function downloadBlob(blob, filename) {
  // Create a link element, use it to download the blob, and remove it
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

Alpine.data('app', () => ({
  releases: [],
  async init() {
    await fetch('/api/releases')
      .then(response => response.json())
      .then(data => {
        this.releases = data
      })
      .catch(error => console.error('Error:', error))
  }
}))
Alpine.start()
